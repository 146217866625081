import { isExploreSlug } from '@/presentation/services/exploreCategories';
import { isNewFeedPaginationEnabled } from '@/presentation/services/featureFlags';
import { FIRST_FEED_PAGE } from '@/presentation/services/feed';
import isSpecialFeedTab from '@/presentation/services/feedTabsCategories';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { BehaviorSubject } from 'rxjs';

const initialPageSubject$ = new BehaviorSubject<number | null>(null);

const getUrlWithoutQuery = (url: string) => url.split('?')[0];

const getSlugFromUrl = (url: string) => getUrlWithoutQuery(url).split('/')[1];

export const useInitialFeedPage = (pageSSR: number) => {
  const { asPath, events } = useRouter();
  const isFeedPaginationEnabled = isNewFeedPaginationEnabled();
  const [isInitialPageLoaded, setIsInitialPageLoaded] = useState(false);
  const [initialPage, setInitialPage] = useState(FIRST_FEED_PAGE);

  const shouldApplyInitialPage =
    isFeedPaginationEnabled && initialPage > FIRST_FEED_PAGE;

  useEffect(() => {
    if (initialPageSubject$.value === null) {
      initialPageSubject$.next(pageSSR);
      setIsInitialPageLoaded(true);
    }
  }, [pageSSR]);

  useEffect(() => {
    const handler = (url: string) => {
      const urlWithoutQuery = getUrlWithoutQuery(url);
      const asPathWithoutQuery = getUrlWithoutQuery(asPath);
      const isSamePage = asPathWithoutQuery === urlWithoutQuery;

      const slug = getSlugFromUrl(url);

      const shouldResetInitialPage =
        isSpecialFeedTab(slug) || isExploreSlug(slug);

      if (isSamePage || !shouldResetInitialPage) return;

      initialPageSubject$.next(FIRST_FEED_PAGE);
    };
    events.on('routeChangeStart', handler);

    return () => {
      events.off('routeChangeStart', handler);
    };
  }, [asPath, events]);

  useEffect(() => {
    const initialPageSubscription = initialPageSubject$.subscribe(
      (initialPage) => {
        setInitialPage(initialPage ?? FIRST_FEED_PAGE);
      }
    );

    return () => {
      initialPageSubscription.unsubscribe();
    };
  }, []);

  const resetInitialPageValue = () => {
    initialPageSubject$.next(FIRST_FEED_PAGE);
  };

  return {
    initialPage: shouldApplyInitialPage ? initialPage : undefined,
    resetInitialPageValue,
    isInitialPageLoaded,
  };
};
