import { useEffect } from 'react';
import { useModal } from '@pelando/components';
import { Check, TriangleExclamation } from '@pelando/fontawesome/icons';
import { CheckIcon, ErrorIcon, ModalStyle } from './styles';
import { ConfirmationEmailStatus } from '../../types/schema';
import { ModalContent, ModalContentProps } from './component';

export type UseIdentifyConfirmEmailRoutesParams = {
  status?: ConfirmationEmailStatus;
  onResendToken?: () => void;
  onFinish?: () => void;
};

export function useIdentifyConfirmEmailRoutes({
  status,
  onResendToken,
}: UseIdentifyConfirmEmailRoutesParams) {
  const { showModal, closeModal } = useModal();

  const isConfirmationStatus = Object.values(ConfirmationEmailStatus).includes(
    status as ConfirmationEmailStatus
  );

  const getModalContentByStatus = (): ModalContentProps =>
    ({
      [ConfirmationEmailStatus.SUCCESS]: {
        icon: <CheckIcon icon={Check} />,
        title: 'Tudo certo!',
        content: 'Email confirmado com sucesso.',
        buttonText: 'Continuar',
        onConfirm: closeModal,
      },
      [ConfirmationEmailStatus.EXPIRED_TOKEN]: {
        icon: <ErrorIcon icon={TriangleExclamation} />,
        title: 'Opss!',
        content: 'Link de confirmação expirado.',
        buttonText: 'Reenviar confirmação',
        onConfirm: () => {
          onResendToken?.();
          closeModal();
        },
      },
      [ConfirmationEmailStatus.ERROR]: {
        icon: <ErrorIcon icon={TriangleExclamation} />,
        title: 'Opss!',
        content: 'Erro ao validar seu email. Por favor, tente mais tarde.',
      },
    }[status as ConfirmationEmailStatus]);

  useEffect(() => {
    if (!isConfirmationStatus) return;
    const content = getModalContentByStatus();
    showModal(<ModalContent {...content} />, {
      style: ModalStyle,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, onResendToken]);
}
