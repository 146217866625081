import {
  getDefaultDescription,
  getDefaultTitle,
  getRssUrl,
} from '@/presentation/services/seo/index';
import { getExploreItemsTitleBySlug } from '@/presentation/services/exploreCategories';
import {
  ExploreCommunities,
  getExploreCommunitiesItemsTitleBySlug,
} from '@/presentation/services/exploreCommunities';
import isSpecialFeedTab, {
  SpecialFeedTabSlugEnUS,
  SpecialFeedTabSlugPtBR,
  getSpecialFeedTitleBySlug,
} from '@/presentation/services/feedTabsCategories';
import { getFeedUrl, isFeedSlugValid } from '@/presentation/services/urls/feed';
import {
  isNewFeedPaginationEnabled,
  isShowCommunitiesEnabled,
} from '@/presentation/services/featureFlags';
import { getLongMonth } from '@/presentation/services/time';
import { getCanonicalUrl } from '@/presentation/services/urls';
import { Langs, getTranslatedString } from '@/locales';

const getCategoryTitle = (slug: string, locale: string): string =>
  getSpecialFeedTitleBySlug(slug, locale as Langs) ||
  getExploreItemsTitleBySlug(slug) ||
  getExploreCommunitiesItemsTitleBySlug(slug as ExploreCommunities, locale);

type GetNewFeedTitleParams = {
  slug: string;
  page?: number;
  locale?: string;
};

export const getNewFeedTitle = ({
  slug,
  page = 1,
  locale,
}: GetNewFeedTitleParams) => {
  if (
    !isFeedSlugValid(slug) ||
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.FEATURED
  ) {
    return getDefaultTitle();
  }

  const categoryTitle = getCategoryTitle(slug, locale || '');

  if (!isNewFeedPaginationEnabled() && !isShowCommunitiesEnabled()) {
    const year = new Date(Date.now()).getFullYear();
    const month = getLongMonth(new Date(Date.now()), locale);
    return getTranslatedString(
      'feed',
      'old-seo-title',
      categoryTitle,
      month,
      year
    );
  }

  const pageSuffix =
    page > 1 && isNewFeedPaginationEnabled()
      ? getTranslatedString('feed', 'seo-page-suffix', page)
      : '';

  const slugPrefix = `${categoryTitle}:`;

  return getTranslatedString('feed', 'seo-title', slugPrefix, pageSuffix);
};

type GetFeedDescriptionParams = {
  slug?: string;
  locale?: string;
};

export const getFeedDescription = ({
  locale,
  slug,
}: GetFeedDescriptionParams) => {
  if (
    !slug ||
    !isFeedSlugValid(slug) ||
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.FEATURED
  ) {
    return getDefaultDescription();
  }

  const categoryTitle = getCategoryTitle(slug, locale || '');

  if (isShowCommunitiesEnabled()) {
    return getTranslatedString('feed', 'seo-description', categoryTitle);
  }

  const year = new Date(Date.now()).getFullYear();
  const month = getLongMonth(new Date(Date.now()), locale);
  return getTranslatedString(
    'feed',
    'old-seo-description',
    categoryTitle,
    month,
    year
  );
};

export const getHomeFeedRss = (slug: string) => {
  if (!isFeedSlugValid(slug) || !slug) return undefined;

  if (isSpecialFeedTab(slug)) {
    return getRssUrl(
      {
        [SpecialFeedTabSlugPtBR.FEATURED]: 'highlights',
        [SpecialFeedTabSlugPtBR.RECENT]: 'recents',
        [SpecialFeedTabSlugPtBR.HOTTEST]: 'hottest',
        [SpecialFeedTabSlugPtBR.COMMENTED]: 'commented',
        [SpecialFeedTabSlugEnUS.FEATURED]: 'highlights',
        [SpecialFeedTabSlugEnUS.RECENT]: 'recents',
        [SpecialFeedTabSlugEnUS.HOTTEST]: 'hottest',
        [SpecialFeedTabSlugEnUS.COMMENTED]: 'commented',
      }[slug]
    );
  }

  return getRssUrl(`cat/${slug}`);
};

export const getFeedCanonical = ({
  slug,
  page = 1,
}: {
  slug: string;
  page?: number;
}) => {
  if (!isFeedSlugValid(slug) || !slug) return undefined;

  const isFeaturedCategory =
    slug === SpecialFeedTabSlugPtBR.FEATURED ||
    slug === SpecialFeedTabSlugEnUS.FEATURED;

  const pathname = !isFeaturedCategory
    ? getFeedUrl(slug, { page })
    : getFeedUrl();

  return getCanonicalUrl(pathname);
};
