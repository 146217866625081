import { ExploreCategories } from '../../exploreCategories';
import {
  ExploreCommunities,
  isExploreCommunitiesSlug,
} from '../../exploreCommunities';
import { isNewFeedPaginationEnabled } from '../../featureFlags';
import isSpecialFeedTab, {
  SpecialFeedTabSlugPtBR,
  SpecialFeedTabSlugEnUS,
} from '../../feedTabsCategories';

type GetFeedUrlOptions = {
  page?: number;
};

export type ValidFeedSlug =
  | ExploreCommunities
  | ExploreCategories
  | SpecialFeedTabSlugPtBR
  | SpecialFeedTabSlugEnUS;

export const isFeedSlugValid = (feed: string): feed is ValidFeedSlug =>
  isExploreCommunitiesSlug(feed) || isSpecialFeedTab(feed);

export function getFeedUrl(
  feed: ValidFeedSlug = SpecialFeedTabSlugPtBR.FEATURED,
  options?: GetFeedUrlOptions
) {
  const params = new URLSearchParams();

  if ((options?.page || 0) > 1 && isNewFeedPaginationEnabled()) {
    params.append('page', String(options?.page));
  }

  const searchParams = params.toString() ? `?${params.toString()}` : '';

  const slug = feed !== SpecialFeedTabSlugPtBR.FEATURED ? feed : '';

  return `/${slug}${searchParams}`;
}
